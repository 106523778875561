exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.push([module.id, "@import url(//fonts.googleapis.com/css?family=Roboto&subset=cyrillic);", ""]);

// module
exports.push([module.id, ":root {\n  /*\n   * Typography\n   * ======================================================================== */\n\n  --font-family-base: 'Roboto', sans-serif;\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  --max-content-width: 1000px;\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */\n\n  --screen-xs-min: 480px; /* Extra small screen / phone */\n  --screen-sm-min: 768px; /* Small screen / tablet */\n  --screen-md-min: 992px; /* Medium screen / desktop */\n  --screen-lg-min: 1200px; /* Large screen / wide desktop */\n\n  /*\n     * Colors\n     * ======================================================================== */\n  --main-color: #4195c3;\n  --secondary-color: #3d3d3d;\n  --light-main: #c6d9f0;\n}\n\n.O9oW9 {\n  background: #3d3d3d;\n  color: #fff;\n  border-bottom: 2px solid #4195c3;\n  border-bottom: 2px solid var(--main-color);\n}\n\n.qQ2mF {\n  margin: 0 auto;\n  padding: 15px 0 15px 10px;\n  max-width: 1000px;\n  max-width: var(--max-content-width);\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: justify;\n      justify-content: space-between;\n}\n\n._2oS_y {\n  color: rgb(104, 171, 207);\n  color: color(var(--main-color) lightness(+10%));\n  text-decoration: none;\n  font-size: 1em;\n  display: -ms-inline-flexbox;\n  display: inline-flex;\n  -ms-flex-align: start;\n      align-items: flex-start;\n}\n\n@media screen and (max-width: 1200px) {\n  .qQ2mF {\n    padding: 15px 0 15px 10px;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"root": "O9oW9",
	"container": "qQ2mF",
	"brand": "_2oS_y"
};