import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import history from '../../history';
import { toLocalized } from '../../helpers/locale';

function isLeftClickEvent(event) {
  return event.button === 0;
}

function isModifiedEvent(event) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

class Link extends React.Component {
  static propTypes = {
    to: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    className: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.string,
      PropTypes.number,
      PropTypes.node,
      PropTypes.object,
    ]),
    locale: PropTypes.string.isRequired,
    currentPath: PropTypes.string.isRequired,
  };

  static defaultProps = {
    onClick: null,
    className: null,
  };
  handleClick = event => {
    const { to, locale } = this.props;

    if (this.props.onClick) {
      this.props.onClick(event);
    }

    if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
      return;
    }

    if (event.defaultPrevented === true) {
      return;
    }

    event.preventDefault();
    if (to) {
      history.push(toLocalized(to, locale));
    }
  };

  render() {
    const { to, children, locale, currentPath, ...props } = this.props;
    const isSelected =
      toLocalized(to, locale) === currentPath ? 'activeLink' : '';
    return (
      <a
        href={toLocalized(to, locale)}
        {...props}
        className={cx(this.props.className, isSelected)}
        onClick={this.handleClick}
      >
        {children}
      </a>
    );
  }
}

const mapStateToProps = ({ intl, history }) => ({
  locale: intl.locale,
  currentPath: history.currentPath,
});

export default connect(mapStateToProps, {})(Link);
