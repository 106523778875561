import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import s from './Header.css';
import Link from '../Link';
import logoUrl from './logo.svg';
import CurrencySwitcher from '../CurrencySwitcher';
import LanguageSwitcher from '../LanguageSwitcher';

class Header extends React.Component {
  render() {
    return (
      <header className={s.root}>
        <div className={s.container}>
          <Link className={cx(s.brand, 'no-hover')} to="/">
            <img src={logoUrl} width="90" alt="E-Shop" />
          </Link>
          <CurrencySwitcher />
          <LanguageSwitcher />
        </div>
      </header>
    );
  }
}

export default withStyles(s)(Header);
