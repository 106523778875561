/* eslint-disable import/prefer-default-export */

import { CURRENCY } from '../constants';

export const setCurrency = currency => dispatch => {
  if (process.env.BROWSER) {
    document.cookie = `currency=${currency};path=/;max-age=${3650 * 24 * 3600}`;
  }
  dispatch({
    type: CURRENCY.SET,
    payload: currency,
  });
};
