/* eslint-disable no-shadow */

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';
import { setLocale } from '../../actions/intl';
import { toLocalized, removeLocaleFromHref } from '../../helpers/locale';
import s from './LanguageSwitcher.css';

const localeDict = {
  en: 'English',
  ru: 'Russian',
  // cs: 'Česky',
};

class LanguageSwitcher extends React.Component {
  static propTypes = {
    currentLocale: PropTypes.string.isRequired,
    availableLocales: PropTypes.arrayOf(PropTypes.string).isRequired,
    setLocale: PropTypes.func.isRequired,
    currentPath: PropTypes.string.isRequired,
  };
  state = {
    active: false,
  };
  switchTab = () => {
    this.setState({
      active: !this.state.active,
    });
  };
  render() {
    const {
      currentLocale,
      availableLocales,
      setLocale,
      currentPath,
    } = this.props;
    const isSelected = locale => locale === currentLocale;
    const localeName = locale => localeDict[locale] || locale;
    const Path = removeLocaleFromHref(currentPath);
    return (
      <div className={s.root}>
        {availableLocales.map(locale => (
          <span key={locale}>
            {isSelected(locale) && (
              <button className={s.btn} onClick={this.switchTab}>
                {localeName(locale)}
              </button>
            )}
          </span>
        ))}
        <div className={cx(s.popUp, this.state.active ? s.active : '')}>
          {availableLocales.map(locale => (
            <span key={locale}>
              {isSelected(locale) ? (
                <a className={s.current} href={toLocalized(Path, locale)}>
                  {localeName(locale)}
                </a>
              ) : (
                <a
                  href={toLocalized(Path, locale)}
                  onClick={() => {
                    setLocale({ locale });
                  }}
                >
                  {localeName(locale)}
                </a>
              )}
            </span>
          ))}
        </div>
      </div>
    );
  }
}

const mapState = state => ({
  availableLocales: state.runtime.availableLocales,
  currentLocale: state.intl.locale,
  currentPath: state.history.currentPath,
});

const mapDispatch = {
  setLocale,
};

export default connect(mapState, mapDispatch)(withStyles(s)(LanguageSwitcher));
