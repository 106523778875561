import React from 'react';
import { FormattedMessage } from 'react-intl';
import Ink from 'react-ink';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './CheckoutHeader.css';
import logoUrl from '../Header/logo.svg';
import Link from '../Link';

class CheckoutHeader extends React.Component {
  render() {
    return (
      <header className={s.root}>
        <div className={s.container}>
          <Link to="/">
            <img alt="logo" src={logoUrl} className={s.logo} />
          </Link>
          <a
            href="https://portal.fondy.eu/mportal/#/account/registration"
            className={s.wantBtn}
          >
            <Ink />
            <FormattedMessage
              id="cart.i_want"
              defaultMessage="I want FONDY checkout"
            />
          </a>
        </div>
      </header>
    );
  }
}

export default withStyles(s)(CheckoutHeader);
