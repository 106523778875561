import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import NProgress from 'react-nprogress';
// external-global styles must be imported in your JS.
import normalizeCss from 'normalize.css';
import * as progress from '../../global_css/progress.css';
import s from './Layout.css';
import global from '../../global_css/global.css';

import Header from '../Header';
import CheckoutHeader from '../CheckoutHeader';

class Layout extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    loading: PropTypes.shape().isRequired,
    alternative: PropTypes.bool,
  };
  static defaultProps = {
    alternative: false,
  };
  componentDidMount() {
    NProgress.configure({
      showSpinner: false,
      easing: 'cubic-bezier(0.95, 0.05, 0.795, 0.035)',
    });
  }
  componentDidUpdate() {
    this.updateLoader();
  }
  updateLoader = () => {
    const { loading } = this.props;

    if (loading.start) {
      NProgress.start();
    }

    if (loading.done) {
      NProgress.set(0.6);
      setTimeout(() => {
        NProgress.done();
      }, 200);
    }
  };
  render() {
    const { alternative } = this.props;
    return (
      <div className={s.root}>
        {alternative ? <CheckoutHeader /> : <Header />}
        {this.props.children}
      </div>
    );
  }
}
const mapStateToProps = ({ loading }) => ({
  loading,
});

export default withStyles(normalizeCss, s, progress, global)(
  connect(mapStateToProps)(Layout),
);
