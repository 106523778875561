/* eslint-disable no-shadow */

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';
import { setCurrency } from '../../actions/currency';
import { AVAILABLE_CURRENCY } from '../../constants';
import s from './CurrencySwitcher.css';

class CurrencySwitcher extends React.Component {
  static propTypes = {
    currentCurrency: PropTypes.string.isRequired,
    availableCurrency: PropTypes.arrayOf(PropTypes.object).isRequired,
    setCurrency: PropTypes.func.isRequired,
  };
  state = {
    active: false,
  };
  switchTab = () => {
    this.setState({
      active: !this.state.active,
    });
  };
  render() {
    const { currentCurrency, availableCurrency, setCurrency } = this.props;
    const Self = this;
    const isSelected = currency => currency === currentCurrency;
    return (
      <div className={s.root}>
        {availableCurrency.map(currency => (
          <span key={currency.code}>
            {isSelected(currency.code) && (
              <button className={s.btn} onClick={this.switchTab}>
                <span className={s.symbol}>{currency.symbol}</span>
                <span className={s.code}>{currency.code}</span>
              </button>
            )}
          </span>
        ))}
        <div className={cx(s.popUp, this.state.active ? s.active : '')}>
          {availableCurrency.map(currency => (
            <span key={currency.code}>
              {isSelected(currency.code) ? (
                <span className={s.current}>{currency.code}</span>
              ) : (
                <button
                  className={s.item}
                  value={currency}
                  onClick={e => {
                    e.preventDefault();
                    setCurrency(currency.code);
                    Self.switchTab();
                  }}
                >
                  {currency.code}
                </button>
              )}
            </span>
          ))}
        </div>
      </div>
    );
  }
}

const mapState = state => ({
  currentCurrency: state.currency.currentCurrency,
  availableCurrency: AVAILABLE_CURRENCY,
});

const mapDispatch = {
  setCurrency,
};

export default connect(mapState, mapDispatch)(withStyles(s)(CurrencySwitcher));
